import Image from "./components/Image";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useToast } from "./components/ui/use-toast";
import GIF from "./components/GIF";

function App() {
  const [goals, setGoals] = useState([]);
  const [results, setResults] = useState({
    goals: [],
    totalMonthlyInvestment: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  // State variables for interest rates
  const [twoYearReturn, setTwoYearReturn] = useState(3);
  const [twoToFiveYearReturn, setTwoToFiveYearReturn] = useState(4);
  const [fivePlusYearReturn, setFivePlusYearReturn] = useState(5);
  const [savings, setSavings] = useState(10000);
  const [investment, setInvestment] = useState(1000);
  const [email, setEmail] = useState("");
  const { toast } = useToast();

  const addGoal = () => {
    setGoals([...goals, { goal: "", years: 0, months: 0, target: 0 }]);
  };

  const updateGoal = (index, field, value) => {
    const newGoals = goals.map((goal, i) =>
      i === index ? { ...goal, [field]: value } : goal
    );
    setGoals(newGoals);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !twoYearReturn ||
      !twoToFiveYearReturn ||
      !fivePlusYearReturn ||
      !savings ||
      !investment ||
      // !email ||
      goals.length === 0
    ) {
      toast({
        title: "Error",
        description: "Please fill in all fields.",
      });
      return;
    }
    setIsLoading(true);

    const payload = {
      rate_short: twoYearReturn,
      rate_medium: twoToFiveYearReturn,
      rate_long: fivePlusYearReturn,
      savings: savings,
      investment: investment,
      email: email,
      goals,
    };

    try {
      const response = await fetch("/my/api/allocation-calculator-1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        toast({
          title: "Got your request!",
          description:
            "We'll analyze your situation soon and report back via email :)",
        });
        // setResults({
        //   goals: data.goals,
        //   totalMonthlyInvestment: data['Total monthly investment']
        // });
      } else {
        console.error("Error submitting goals");
      }
    } catch (error) {
      console.error("Error submitting goals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteGoal = (index) => {
    const updatedGoals = [...goals];
    updatedGoals.splice(index, 1);
    setGoals(updatedGoals);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Goal Calculator</title>
        <meta
          name="description"
          content="Find out how much you need to be investing to achieve your goals"
        />
        <meta property="og:title" content="Goal Calculator by sav" />
        <meta
          property="og:description"
          content="Find out how much you need to be investing to achieve your goals"
        />
        <meta
          property="og:url"
          content={`https://www.whatins.sg/goal-calculator`}
        />
      </Helmet>
      {isLoading ? (
        <GIF src="loading.mp4" />
      ) : (
        <section className="py-6 bg-slate-100 dark:bg-gray-900 space-y-6">
          <div className="flex flex-col md:flex-row px-4 gap-4 mx-auto max-w-screen-lg">
            <div className="flex flex-col pb-8 gap-y-6">
              <h1 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance capitalize">
                Goal Calculator
              </h1>
              <Image
                src="goal-calculator-1x1.png"
                className="block md:hidden"
              />
              {/* <h2 className='text-2xl'>$7</h2> */}
              <p className="text-start">
                You have a list of goals and have a rough idea as to how much
                they'll cost. But how do you actually invest your money properly
                so that you'll be able to actually achieve those goals by the
                time you want WHILE investing for your longer term plans like
                retirement?
              </p>
              <p className="text-start">
                This calculator will tell you exactly how much you need to
                invest for each goal so that you're able to achieve them. Try it
                out yourself!
              </p>
              {/* <a className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 rounded text-center mx-auto px-8" href="https://cart.sav.finance/robo-explorer/">Purchase</a> */}
            </div>
            <div className="hidden md:block">
              <Image src="goal-calculator-1x1.png" />
            </div>
          </div>
          <div className="max-w-screen-lg flex-col mx-4 md:mx-auto *:py-1">
            <h1 className="text-center md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance">
              What are the expected returns for the different timeframes?
            </h1>
            <p>
              This is what the calculator will assume you will be able to get
              each year.
            </p>
            <div className="flex flex-col md:flex-row px-4 mx-auto max-w-screen-lg gap-4">
              <div className="flex-col border rounded-2xl p-4">
                <label className="font-semibold">2 Year Return (%):</label>
                <input
                  type="number"
                  value={twoYearReturn}
                  onChange={(e) => {
                    const value = Math.max(0, Number(e.target.value));
                    setTwoYearReturn(value);
                  }}
                  className="text-center border rounded-xl bg-white"
                  min="0"
                />
              </div>
              <div className="flex-col border rounded-2xl p-4">
                <label className="font-semibold">2-5 Year Return (%): </label>
                <input
                  type="number"
                  value={twoToFiveYearReturn}
                  onChange={(e) => {
                    const value = Math.max(0, Number(e.target.value));
                    setTwoToFiveYearReturn(value);
                  }}
                  className="text-center border rounded-xl bg-white"
                  min="0"
                />
              </div>
              <div className="flex-col border rounded-2xl p-4">
                <label className="font-semibold">5+ Year Return (%): </label>
                <input
                  type="number"
                  value={fivePlusYearReturn}
                  onChange={(e) => {
                    const value = Math.max(0, Number(e.target.value));
                    setFivePlusYearReturn(value);
                  }}
                  className="text-center border rounded-xl bg-white"
                  min="0"
                />
              </div>
            </div>
          </div>
          <div className="max-w-screen-lg flex-col mx-4 md:mx-auto *:py-1">
            <h1 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance">
              What goals do you have?
            </h1>
            <p>
              For each goal, enter in when you would ideally like to achieve it
              (in months and years) and how much you think it'll cost at that
              time.
            </p>
            {goals.map((goal, index) => (
              <div
                className="*:py-1 flex flex-col md:flex-row mb-4 md:gap-2"
                key={index}
              >
                <div>
                  <label>Goal:</label>
                  <input
                    type="text"
                    className="text-center rounded-xl"
                    value={goal.name}
                    onChange={(e) => updateGoal(index, "name", e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Years: </label>
                  <input
                    type="number"
                    className="text-center rounded-xl"
                    value={goal.years}
                    onChange={(e) => updateGoal(index, "years", e.target.value)}
                  />
                </div>
                <div>
                  <label>Months: </label>
                  <input
                    type="number"
                    className="text-center rounded-xl"
                    value={goal.months}
                    onChange={(e) =>
                      updateGoal(index, "months", e.target.value)
                    }
                  />
                </div>
                <div>
                  <label>Target: </label>
                  <input
                    type="number"
                    className="text-center rounded-xl"
                    value={goal.target}
                    onChange={(e) =>
                      updateGoal(index, "target", e.target.value)
                    }
                    required
                  />
                </div>
                <button onClick={() => deleteGoal(index)}>Delete</button>
              </div>
            ))}
            <div className="flex flex-col md:flex-row gap-8 justify-center">
              <button
                className="p-4 bg-purple-300 basis-1/3 rounded-2xl"
                onClick={addGoal}
              >
                Add more goals
              </button>
            </div>
          </div>
          <div className="max-w-screen-lg flex-col mx-4 md:mx-auto *:py-1">
            <h1 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance">
              What's your current situation?
            </h1>
            <p>How much have you put away and can put away going forward?</p>
            <div className="flex flex-col md:flex-row px-4 mx-auto max-w-screen-lg gap-4">
              <div className="flex-col border rounded-2xl p-4">
                <label className="font-semibold">
                  Investment Money (non emergency savings fund):
                </label>
                <input
                  type="number"
                  value={savings}
                  onChange={(e) => {
                    const value = Math.max(0, Number(e.target.value));
                    setSavings(value);
                  }}
                  className="text-center border rounded-xl bg-white"
                  min="0"
                />
              </div>
              <div className="flex-col border rounded-2xl p-4">
                <label className="font-semibold">
                  Monthly Investment budget:{" "}
                </label>
                <input
                  type="number"
                  value={investment}
                  onChange={(e) => {
                    const value = Math.max(0, Number(e.target.value));
                    setInvestment(value);
                  }}
                  className="text-center border rounded-xl bg-white"
                  min="0"
                />
              </div>
            </div>
          </div>
          <div className="max-w-screen-lg flex-col mx-4 md:mx-auto *:py-1">
            <h1 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance">
              Where should we send the results?
            </h1>
            <div className="flex flex-col md:flex-row px-4 mx-auto max-w-screen-lg gap-4">
              <div className="flex-col p-4">
                <label className="font-semibold">Your email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-2 border rounded-xl bg-white"
                  required
                />
              </div>
              <button
                className="p-4 bg-purple-300 basis-1/3 rounded-2xl"
                onClick={handleSubmit}
              >
                Get results
              </button>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default App;
