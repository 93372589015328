import React, { useState, useEffect } from 'react';
import { useToast } from "./components/ui/use-toast"
import { useLocation } from 'react-router-dom';

const MoneyDiaryChallenge = () => {
  const [diaryData, setDiaryData] = useState([]);
  const [telegramConnected, setTelegramConnected] = useState(false);
  const [remindType, setRemindType] = useState('');
  const [newDay, setNewDay] = useState({ day: 0, purchases: [{ purchase: '', cost: 0, reflection: '' }] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { toast } = useToast()
  const location = useLocation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailFromURL = searchParams.get('email');
    setEmail(emailFromURL);
    fetchDiaryData(emailFromURL);
  }, []);

  const fetchDiaryData = (email) => {
    fetch(`/my/api/get-diary?email=${encodeURIComponent(email)}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setTelegramConnected(data.telegram !== null);
        setRemindType(data.remind_type);
        if (data.data === null || Object.keys(data.data).length === 0) {
          setDiaryData([]);
          setNewDay({ day: 1, purchases: [{ purchase: '', cost: 0, reflection: '' }] });
        } else {
          setDiaryData(data.data);
          setNewDay({ day: data.data.length + 1, purchases: [{ purchase: '', cost: 0, reflection: '' }] });
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching diary data:', error);
        setError(error);
        setLoading(false);
      });
  };

  const handleInputChange = (index, field, value) => {
    const updatedPurchases = [...newDay.purchases];
    updatedPurchases[index][field] = value;
    setNewDay({ ...newDay, purchases: updatedPurchases });
  };

  const addMorePurchases = () => {
    setNewDay({ ...newDay, purchases: [...newDay.purchases, { purchase: '', cost: 0, reflection: '' }] });
  };

  const addNewDay = () => {
    setDiaryData([...diaryData, newDay]);
    setNewDay({ day: newDay.day + 1, purchases: [{ purchase: '', cost: 0, reflection: '' }] });
  };

  const deletePurchase = (index) => {
    const updatedPurchases = [...newDay.purchases];
    updatedPurchases.splice(index, 1);
    setNewDay({ ...newDay, purchases: updatedPurchases });
  };

  const submitDiary = () => {
    fetch('/my/api/get-diary', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([...diaryData, newDay]),
    })
      .then(response => response.json())
      .then(data => {
        fetchDiaryData();
        toast({
          title: "Succes!",
          description: "We got your latest entries! Thank you 🙏",
        });
      })
      .catch(error => {
        console.error('Error submitting diary data:', error);
      });
  };

  const handleEmailReminder = (reminderType) => {
    console.log("change reminder: ", reminderType);
    fetch('/my/api/update-challenge-notification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ remind: reminderType }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data if needed
        setRemindType(data.remind_type);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };


  return (
    <div className='px-4'>
      {diaryData && (
        <>
        {!telegramConnected && !remindType ? (
            <div className='flex flex-col bg-sav-blue-1 p-4 rounded-2xl gap-4 mt-4 max-w-screen-lg mx-auto'>
            <h2 className="font-bold text-xl text-pretty">Get reminded via our telegram bot</h2>
            <p className='text-gray-500'>We'll remind you to fill out your money diary so you won't have to remember</p>
            <div className='flex flex-col md:flex-row gap-4'>
            <button
                  className="inline-block rounded-lg bg-sav-purple-2 px-5 py-3 text-sm font-medium text-white"
                  onClick={() => window.open(`https://t.me/whatins_bot?money_diary=${email}`, '_blank')}
                >
                  Remind me via telegram
                </button>
                <button
                  className="inline-block rounded-lg bg-sav-purple-2 px-5 py-3 text-sm font-medium text-white"
                  onClick={() => handleEmailReminder('email')}
                >
                  Remind me via email
                </button>
            </div>
          </div>
          ) : remindType === 'email' ? (
            <div className='flex flex-col bg-sav-blue-1 p-4 rounded-2xl gap-4 mt-4 max-w-screen-lg mx-auto'>
            <h2 className="font-bold text-xl text-pretty">Get reminded via email</h2>
            <p className='text-gray-500'>You chose to get reminded via email.</p>
            <div className='flex flex-col md:flex-row gap-4'>
            <button
                  className="inline-block rounded-lg bg-sav-purple-2 px-5 py-3 text-sm font-medium text-white"
                  onClick={() => handleEmailReminder('telegram')}
                >
                  Remind me via telegram
                </button>
            </div>
          </div>
          ) : remindType === 'telegram' && !telegramConnected ? (
            <div className='flex flex-col bg-sav-blue-1 p-4 rounded-2xl gap-4 mt-4 max-w-screen-lg mx-auto'>
            <h2 className="font-bold text-xl text-pretty">Get reminded via our telegram bot</h2>
            <p className='text-gray-500'>You chose to get reminded via telegram but you haven't connected it yet. Please connect to our telegram bot and refresh this page.</p>
            <div className='flex flex-col md:flex-row gap-4'>
            <button
                  className="inline-block rounded-lg bg-sav-purple-2 px-5 py-3 text-sm font-medium text-white"
                  onClick={() => window.open(`https://t.me/whatins_bot?money_diary=${email}`, '_blank')}
                >
                  Remind me via telegram
                </button>
                <button
                  className="inline-block rounded-lg bg-sav-purple-2 px-5 py-3 text-sm font-medium text-white"
                  onClick={() => handleEmailReminder('email')}
                >
                  Remind me via email
                </button>
            </div>
          </div>
          ) : (
            <p>telegram</p>
          )}
      {newDay && newDay.day < 8 && (
        <div className='flex flex-col max-w-screen-lg mx-auto rounded-2xl mt-2'>
        <div className='grid grid-cols-2 mb-8 sticky top-0 bg-white'>
      <div className='flex max-w-xl mx-auto mt-8'><button className='primary-button2' onClick={submitDiary}>Submit day {newDay.day}</button></div>
      <div className='flex max-w-xl mx-auto mt-8'><button className='primary-button2' onClick={addNewDay}>Submit day {newDay.day} & add day {newDay.day + 1}</button></div>
      </div>

      <h2 className='text-center text-2xl'>Day {newDay.day}</h2>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
      {newDay.purchases.map((purchase, index) => (
        <div key={index} className='grid grid-cols-1 max-w-xl border p-6 rounded-2xl mt-8'>
          <div className='flex flex-col py-1'>
            <p className='font-semibold'>Purchase</p>
          <input
className='mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border'            type="text"
            placeholder="Purchase"
            value={purchase.purchase}
            onChange={(e) => handleInputChange(index, 'purchase', e.target.value)}
          />
          </div>
          <div className='flex flex-col py-1'>
            <p className='font-semibold'>Cost</p>
          <input
            className='mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border'
            type="number"
            placeholder="Cost"
            value={purchase.cost}
            onChange={(e) => handleInputChange(index, 'cost', e.target.value)}
          />
          </div>
          <div className='flex flex-col py-1'>
            <p className='font-semibold'>Reflection (if any)</p>
          <textarea
className='mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border'            placeholder="Reflection"
            value={purchase.reflection}
            rows={3}
            onChange={(e) => handleInputChange(index, 'reflection', e.target.value)}
          />
          </div>
          <button className='bg-purple-400 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded' onClick={() => deletePurchase(index)}>Delete</button>
        </div>
      ))}
      </div>
      <button className='mt-4 primary-button2 px-8 w-full' onClick={addMorePurchases}>Add more purchases</button>
      </div>
      )} 
      {newDay && newDay.day > 7 && (
        <div className='flex flex-col max-w-screen-lg mx-auto'>
        <h2 className='primary-h2 text-center'>You've completed Money Diary!</h2>
        <p className='text-center'>We'll email you once we have the post done up 🥳</p>
        </div>
      )}
      
      <div className='my-8 max-w-xl mx-auto'>
        <h1 className='primary-h3 text-center sticky top-4 bg-white'>What you've submitted so far</h1>
          {diaryData.map((day) => (
            <div key={day.day} className='flex flex-col max-w-xl mx-auto border p-6 rounded-2xl mt-8'>
              <h2 className='text-center text-2xl'> Day {day.day}</h2>
              {day.purchases.map((purchase, index) => (
                <div key={index} className='mt-2'>
                  <div className='flex justify-between'>
                  <p className='font-semibold'>Purchase:</p> <span className='font-normal'>{purchase.purchase}</span>
                  </div>
                  <div className='flex justify-between'>
                  <p className='font-semibold'>Cost:</p> 
                  <p>{purchase.cost}</p>
                  </div>
                  <div className='flex justify-between'>
                  <p className='font-semibold'>Reflection:</p> 
                  <p>{purchase.reflection}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        </>
        )}
    </div>
  );
};

export {MoneyDiaryChallenge};