import React, { useState, useEffect, useRef } from 'react';
import Image from './Image';

interface ImageDisplayProps {
    urls: string[];
    className?: string; // Optional className prop
}

const ImageGrid: React.FC<ImageDisplayProps> = ({ urls, className }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (urls && urls.length > 0) {
            setCurrentImageIndex(0); // Initialize with the first image index
        }
    }, [urls]);

    const updateMainImage = (url: string, index: number) => {
        setCurrentImageIndex(index); // Update to use index for navigation
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const navigateImage = (direction: 'next' | 'prev') => {
        let newIndex = direction === 'next' ? currentImageIndex + 1 : currentImageIndex - 1;
        // Loop back to the start or end if out of bounds
        if (newIndex < 0) {
            newIndex = urls.length - 1;
        } else if (newIndex >= urls.length) {
            newIndex = 0;
        }
        setCurrentImageIndex(newIndex);
    };

    if (!urls || urls.length === 0) {
        return <div>No images to display</div>;
    }

    const currentImageUrl = `/images/${urls[currentImageIndex]}`;

    // Apply provided className or default to "flex flex-col gap-4" if none is given
    const containerClass = className || "flex flex-col gap-4";

    return (
        <div className={containerClass}>
            <div>
                <img
                    id="main-img"
                    className="h-auto max-w-full rounded-xl cursor-pointer"
                    src={currentImageUrl}
                    alt="Main Display"
                    loading="lazy"
                    onClick={toggleModal}
                />
            </div>
            <div className="grid grid-cols-5 gap-4">
                {urls.map((url, index) => (
                    <div key={index}>
                        <img
                            src={`/images/${url}`}
                            className={`thumbnail h-auto max-w-full rounded-lg ${currentImageIndex === index ? 'border-2 border-black' : ''}`}
                            alt="Thumbnail"
                            loading="lazy"
                            onClick={() => updateMainImage(url, index)}
                        />
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div
                className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-50"
                onClick={toggleModal}
            >
                <div className="relative max-w-full max-h-full w-auto h-auto">
                    <img
                        src={currentImageUrl}
                        className="max-w-full max-h-full w-auto h-auto object-contain"
                        alt="Expanded"
                        style={{
                            maxHeight: 'calc(100vh)', // Adjusts based on the viewport height, 1rem is subtracted for some spacing
                            maxWidth: 'calc(100vw)' // Adjusts based on the viewport width, 1rem is subtracted for some spacing
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevents modal close when clicking on the image itself
                    />
                    <button
                        className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white bg-black bg-opacity-0 p-4 "
                        onClick={(e) => { e.stopPropagation(); navigateImage('prev'); }}
                        style={{ zIndex: 10 }}
                    >
                        <Image src='left-arrow.svg' className='w-4 md:w-16'/>
                    </button>
                    <button
                        className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white bg-black bg-opacity-0 p-4"
                        onClick={(e) => { e.stopPropagation(); navigateImage('next'); }}
                        style={{ zIndex: 10 }}
                    >
                      <Image src='right-arrow.svg' className='w-4 md:w-16'/>
                    </button>
                </div>
            </div>
            
            )}
        </div>
    );
};

interface ImageStoryProps {
    urls: string[];
}

export const ImageStory: React.FC<ImageStoryProps> = ({ urls }) => {
    const {
        currentIndex,
        progress,
        isPaused,
        navigateStory,
        togglePause,
        currentImageUrl,
    } = useImageStory({ urls });

    return (
        <div className="relative w-full h-full">
            <img
                src={currentImageUrl}
                alt={`Story image ${currentIndex + 1}`}
                className="w-full h-full object-cover"
            />
            <div className="absolute top-0 left-0 right-0 flex">
                {urls.map((_, index) => (
                    <div 
                        key={index} 
                        className="flex-1 h-1 bg-gray-400 mr-1"
                    >
                        <div 
                            className="h-full bg-white"
                            style={{
                                width: index < currentIndex ? '100%' : 
                                       index === currentIndex ? `${progress}%` : '0%',
                                transition: 'width 0.1s linear'
                            }}
                        />
                    </div>
                ))}
            </div>
            <button
                className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full"
                onClick={() => navigateStory('prev')}
            >
                <Image src='left-arrow.svg' className='w-6 h-6' />
            </button>
            <button
                className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full"
                onClick={() => navigateStory('next')}
            >
                <Image src='right-arrow.svg' className='w-6 h-6' />
            </button>
            <button
                className="absolute bottom-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full"
                onClick={togglePause}
            >
                {isPaused ? '▶' : '⏸'}
            </button>
        </div>
    );
};

export const useImageStory = ({ urls }: ImageStoryProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [progress, setProgress] = useState(0);
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
        startTimer();
        return () => {
            if (timerRef.current) cancelAnimationFrame(timerRef.current);
        };
    }, [currentIndex, isPaused]);

    const startTimer = () => {
        if (timerRef.current) cancelAnimationFrame(timerRef.current);
        setProgress(0);
        if (!isPaused) {
            const startTime = Date.now();
            const updateProgress = () => {
                const elapsedTime = Date.now() - startTime;
                const newProgress = (elapsedTime / 5000) * 100;
                setProgress(Math.min(newProgress, 100));
                if (newProgress < 100) {
                    timerRef.current = requestAnimationFrame(updateProgress);
                } else {
                    navigateStory('next');
                }
            };
            timerRef.current = requestAnimationFrame(updateProgress);
        }
    };

    const navigateStory = (direction: 'next' | 'prev') => {
        setCurrentIndex(prevIndex => {
            if (direction === 'next') {
                return prevIndex === urls.length - 1 ? 0 : prevIndex + 1;
            } else {
                return prevIndex === 0 ? urls.length - 1 : prevIndex - 1;
            }
        });
    };

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    return {
        currentIndex,
        progress,
        isPaused,
        navigateStory,
        togglePause,
        currentImageUrl: `/images/${urls[currentIndex]}`,
    };
};

export default ImageGrid;
