"use client"

import React, { useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "./components/ui/chart";

const chartConfig = {
  contributed: {
    label: "Contributed",
    color: "hsl(var(--chart-1))",
  },
  interest: {
    label: "Interest",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

export function CompoundInterestCalculator() {
  const [formData, setFormData] = useState({
    initialInvestment: 1000,
    monthlyContribution: 100,
    yearsToGrow: 5,
    interestRate: 7
  });
  const [results, setResults] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: parseFloat(value) }));
  };

  const calculateCompoundInterest = () => {
    const { initialInvestment, monthlyContribution, yearsToGrow, interestRate } = formData;
    let total = initialInvestment;
    let contributed = initialInvestment;
    const monthlyRate = interestRate / 100 / 12;
    const chartData = [];

    for (let year = 0; year <= yearsToGrow; year++) {
      chartData.push({
        year,
        total: Math.round(total),
        contributed: Math.round(contributed),
        interest: Math.round(total - contributed)
      });

      for (let month = 1; month <= 12; month++) {
        total = (total + monthlyContribution) * (1 + monthlyRate);
        contributed += monthlyContribution;
      }
    }

    setResults({
      totalValue: total,
      chartData
    });
  };

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <div className="flex flex-col gap-2 mx-auto mt-4">
        <h1 className="text-2xl font-bold text-center">Compound Interest Calculator</h1>
        <p className="text-gray-500 text-center">See how your investments grow over time</p>
      </div>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4 bg-gray-100 p-2 rounded-lg">
          <div className="flex flex-col gap-2 border rounded-lg p-1 bg-white">
              <div className="form-field">
                <label className="form-field__label" htmlFor="initialInvestment">Initial investment</label>
                <div className="flex items-center">
                  <span className="pl-3 pb-2 pt-1 text-gray-500">$</span>
                  <Input
                    className="form-field__input pl-1 border-0"
                    type="number"
                    name="initialInvestment"
                    id="initialInvestment"
                    value={formData.initialInvestment}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 border rounded-lg p-1 bg-white">
              <div className="form-field">
                <label className="form-field__label" htmlFor="monthlyContribution">Monthly contribution</label>
                <div className="flex items-center">
                  <span className="pl-3 pb-2 pt-1 text-gray-500">$</span>
                  <Input
                    className="form-field__input pl-1 border-0"
                    type="number"
                    name="monthlyContribution"
                    id="monthlyContribution"
                    value={formData.monthlyContribution}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 border rounded-lg p-1 bg-white">
              <div className="form-field">
                <label className="form-field__label" htmlFor="yearsToGrow">Years to grow</label>
                <div className="flex items-center">
                  <Input
                    className="form-field__input border-0"
                    type="number"
                    name="yearsToGrow"
                    id="yearsToGrow"
                    value={formData.yearsToGrow}
                    onChange={handleInputChange}
                  />
                  <span className="text-gray-500 mr-2">years</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 border rounded-lg p-1 bg-white">
            <div className="form-field">
                <label className="form-field__label" htmlFor="interestRate">Annual interest rate</label>
                <div className="flex items-center">
                <Input
              type="number"
              name="interestRate"
              value={formData.interestRate}
              onChange={handleInputChange}
              placeholder="Annual interest rate (%)"
              className="border-0"
            />
                <span className="text-gray-500 mr-2">%</span>
                </div>
              </div>

            </div>
            
            <Button variant="primary" onClick={calculateCompoundInterest}>Calculate</Button>
          </div>
          
          {results && (
            <div>
              <h2 className="text-2xl font-bold mb-4">
                Total value after {formData.yearsToGrow} years:
              </h2>
              <p className="text-4xl font-bold mb-8">
                ${results.totalValue.toFixed(2)}
              </p>
              <ChartContainer config={chartConfig}>
                <LineChart
                  data={results.chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="year"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <YAxis />
                  <ChartTooltip content={<ChartTooltipContent />} />
                  <Line
                    type="monotone"
                    dataKey="contributed"
                    stroke="var(--color-contributed)"
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="interest"
                    stroke="var(--color-interest)"
                    strokeWidth={2}
                  />
                  <ChartLegend content={<ChartLegendContent />} />
                </LineChart>
              </ChartContainer>
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter>
        <p className="text-sm text-muted-foreground">
          This calculator provides an estimate and does not account for taxes or inflation.
        </p>
      </CardFooter>
    </Card>
  );
}