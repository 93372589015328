import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, Navigate } from "react-router-dom";
import { GlobalProvider } from "./pages/GlobalState";
// import { useLocation } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProfilePage from "./pages/ProfilePage";
import Workshop from "./pages/Workshop";
import Nav from "./pages/components/Nav";
import GeneralNav from "./pages/components/GeneralNav";
import Resources from "./pages/Resources";
import CareerPage from "./pages/CareerPage";
import Login from "./pages/Login";
import Robo from "./pages/Robo";
import CrystalBall from "./pages/CrystalBall";
import RoboGuide from "./pages/lp/RoboGuide";
import InvestingCourse from "./pages/lp/InvestingCourse";
import RetirementLP from "./pages/lp/RetirementLP";
import MoneyPlanner from "./pages/lp/MoneyPlanner";
import GoalCalculator from "./pages/GoalCalculator";
import { MoneyDiaryChallenge } from "./pages/money-diary";
import { ChallengesLP, WeddingDiary, MoneyDiaryStart } from "./pages/lp/Diaries";
import Main from "./pages/lp/Main";
import Org from "./pages/lp/Org";
import CollectionProducts from "./pages/lp/CollectionProducts";
import CollectionFree from "./pages/lp/CollectionFree";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import TagManager from "react-gtm-module";
import { Toaster } from "./pages/components/ui/toaster";
import { CompoundInterestCalculator } from "./pages/Tools";

const tagManagerArgs = {
  gtmId: "GTM-NQK4JWZ",
};
TagManager.initialize(tagManagerArgs);

const useGTMPageView = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname,
      },
    });
  }, [location.pathname]);
};

const MemberLayout = () => (
  <>
    <Nav />
    <Outlet />
  </>
);

const GeneralLayout = () => {
  useGTMPageView();

  return (
    <>
      <GeneralNav />
      <Outlet />
    </>
  );
};

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GeneralLayout />}>
          <Route index element={<Main />} />
          <Route path="work-with-us" element={<Org />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route
          path="/my/"
          element={
            <GlobalProvider>
              {" "}
              <MemberLayout />{" "}
            </GlobalProvider>
          }
        >
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="workshop" element={<Workshop />} />
          <Route path="workshop/:id" element={<Workshop />} />
          <Route path="resources" element={<Resources />} />
          <Route path="career" element={<CareerPage />} />
          <Route path="robo-guide" element={<Robo />} />
          <Route path="crystal-ball" element={<CrystalBall />} />
        </Route>
        <Route path="/diaries/*" element={<GeneralLayout />}>
          <Route index element={<ChallengesLP />} />
          <Route path="money-diary" element={<MoneyDiaryStart />} />
          <Route path="money-diary-start" element={<MoneyDiaryChallenge />} />
          <Route path="wedding-diary" element={<WeddingDiary />} />
        </Route>
        <Route path="/products/*" element={<GeneralLayout />}>
          <Route index element={<CollectionProducts />} />
          <Route path="collection" element={<CollectionProducts />} />
          <Route path="robo-explorer" element={<RoboGuide />} />
          <Route path="investing-course" element={<InvestingCourse />} />
          <Route path="money-planner" element={<MoneyPlanner />} />
        </Route>
        <Route path="/free/*" element={<GeneralLayout />}>
          <Route index element={<CollectionFree />} />
          <Route path="goal-calculator" element={<GoalCalculator />} />
          <Route path="retirement-calculator" element={<RetirementLP />} />
          <Route path="tools" element={<CompoundInterestCalculator />} />
        </Route>
        <Route path="/member/*" element={<GeneralLayout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="/go/*" element={<GeneralLayout />}>
          <Route index element={<Login />} />
          <Route
            path="digital-planner"
            element={
              <ExternalRedirect to="https://drive.google.com/drive/folders/15X9FSpAaThebFfOs0JXAFN69v3IKeUFG?usp=drive_link" />
            }
          />
        </Route>
        <Route path="/planner/*" element={<GeneralLayout />}>
          <Route index element={<Login />} />
          <Route
            path="insurance"
            element={
              <ExternalRedirect to="https://drive.google.com/file/d/1cWwOFm9uW3mYLcUflKxYgDOK5tvTaqFS/view?usp=drive_link" />
            }
          />
          <Route
            path="invest"
            element={
              <ExternalRedirect to="https://drive.google.com/drive/folders/15X9FSpAaThebFfOs0JXAFN69v3IKeUFG?usp=drive_link" />
            }
          />
        </Route>
      </Routes>
      <Toaster />
    </Router>
  );
};

export default App;
